<template>
  <!-- eslint-disable -->
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <div class="main-container">
          <div class="title-block">
            <p>Review your Subscription</p>
          </div>
          <!-- <div class="main-block">
            <div class="img-block">
              <img :src="data.image_url" alt="Property image" />
            </div>
            <div class="address-block">
              <div class="address-img">
                <i class="fa fa-map-marker" />
              </div>
              <div class="address-text">
                <p>Address: {{ data.address }}</p>
              </div>
            </div>
          </div> -->
          <div class="info-block">
            <!-- <p>Next biling</p> -->
            <!-- <div v-if="data.days_left">
              <p class="green-text">{{ data.days_left }} days left.</p>
            </div> -->
            <div>
              <div v-if="data.canceled">
                <p>
                  Will be canceled on:
                  <span class="green-text">{{
                    showEndData(data.end_date)
                  }}</span>
                </p>
              </div>
              <div v-else>
                <p>
                  Next Billing Date:
                  <span class="green-text">{{
                    showEndData(data.end_date)
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="done-btn" @click="closeDialog">Done</button>
            <!-- <button class="done-btn" @click="goToProperty">
              Go to property
            </button> -->
          </div>
          <a
            v-if="!data.days_left && !data.canceled"
            class="cancel-link"
            @click="cancelPpropertySubscription"
            >Click here to cancel this subscription.</a
          >
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
export default {
  name: "SubscriptionInfoPopup",
  components: {},

  props: {
    data: Object,
    dialog: {
      default: false,
    },
  },

  data: () => ({}),

  computed: {
    ...mapGetters("auth", ["isCognitoUserLoggedIn", "cognitoUser"]),
  },

  methods: {
    showEndData(end_date) {
      var formatedDate = new Date(end_date);
      formatedDate = formatedDate
        .toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
        .replace(",", "");
      return formatedDate;
    },

    closeDialog() {
      this.$emit("update:dialog", false);
    },

    cancelPpropertySubscription() {
      const boxes = document.getElementsByClassName("cancel-link");
      for (const box of boxes) {
        box.style.visibility = "hidden";
      }

      axios
        .post(
          "https://api.honely.com/dev/payments/v2/cancel-subscription",
          { "subscription-id": this.data.subscription_id },
          {
            headers: {
              Authorization:
                "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
            },
          }
        )
        .then((response) => {
          this.data.canceled = true;
          this.closeDialog();
        })
        .catch((error) => {
          console.log("[ERROR]", error.response.data.error);
          for (const box of boxes) {
            box.style.visibility = "visible";
          }
        });
    },

    goToProperty() {
      this.$router.push({
        path: "forecast",
        query: { address: this.data.address },
      });
    },
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-block p {
  text-align: center;
  margin: 1rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.main-block {
  display: flex;
  margin: 1rem;
}

.img-block {
  width: 50%;
  padding: 8px;
}
.img-block img {
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.address-block {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.address-img {
  min-width: 20px;
  display: flex;
  justify-content: center;
  /* margin-top: 1.2rem; */
  color: #808080;
}

.address-text {
  padding: 3px;
}

.info-block {
  text-align: center;
  width: 100%;
}
.info-block p {
  margin: 0 1.5rem;
  padding-bottom: 1rem;
}
.btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.done-btn {
  background-color: #61c857;
  color: #ffffff;
  border: none;
  min-width: 165px;
  margin-bottom: 1rem;
}
.done-btn:hover {
  background-color: #5aba52;
  color: #e8e8e8;
}
.cancel-link {
  padding-bottom: 1rem;
  text-align: center;
}

@media screen and (max-width: 400px) {
  .main-block {
    margin: 0 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img-block {
    width: 90%;
  }

  .img-block img {
    height: 150px;
    width: 100%;
  }

  .address-block {
    width: 90%;
  }

  .address-img {
    margin-top: 0.8rem;
  }

  .info-block p {
    margin: 0 2.1rem;
  }

  .green-text {
    color: #24cb43;
  }

  .btn-wrapper {
    flex-direction: column;
    align-items: center;
    width: auto;
  }
  .done-btn {
    min-width: 250px;
  }
}
</style>
